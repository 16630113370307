<template>
  <div class="ibox files">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.processesTitle) }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row">

        <b-table 
            responsive
            outlined striped
            :items="currentProcesses"
            :fields="processesFields"
            show-empty
            :empty-text="getLocalizedText(labels.noProcesses)"
            ref="processesElm">
          <template v-slot:cell(options)="row">
            <a href="javascript:void(0)" @click="followProcess(row.item)">{{ getLocalizedText(labels.followProcess) }}</a>
            <span v-if="allowCancel(row.item)">
               / 
              <a href="javascript:void(0)" @click="confirmCancelProcess(row.item)">{{ getLocalizedText(labels.cancelProcess) }}</a>
            </span>
          </template>
        </b-table>

      </div>

    </div>

    <GenerateProgressModal ref="generateProgressModal" />

    <b-modal ref="cancelProcessModal" 
        hide-header
        @ok="cancelProcess">
      {{ 
        confirmCancelProcessTitle
      }}
    </b-modal>

  </div>
</template>

<style>
  
</style>



<script lang="ts">
import Messages from '@root/src/client/components/panels/Messages.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';
import GenerateProgressModal from './GenerateProgressModal.vue';

import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { useGenerateAdmin } from '../../composables/useGenerateAdmin';

export default defineComponent({
  props: {
      
  },
  components: {
    Messages,
    Select,
    GenerateProgressModal
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedModule, modules } = useModuleAdmin(props, context);
    const { isProcessLoading, cancelProcess:cancelProcessAdmin, currentProcesses } = useGenerateAdmin(props, context);

    const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

    const generateProgressModal:Ref<HTMLElement|null> = ref(null);
     
    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const currentUserLogin:ComputedRef<string> = computed(() => {
      return $store.state.authentication.user.login;
    })

    const processesFields = [
      {
        key: "data.fileName",
        label: ""
      },
      {
        key: "user",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

    const cancelProcessModal:Ref<HTMLElement|null> = ref(null);
    const processesElm:Ref<HTMLElement|null> = ref(null);

    const labels = {
      "headerFileName" : {
        "en": "Source file",
        "fr": "Fichier source"
      },
      "headerUser" : {
        "en": "User",
        "fr": "Utilisateur"
      },
      "headerOptions" : {
        "en": "Options",
        "fr": "Options"
      },
      "confirmCancelProcess" : {
        "en": "Are you sure you want to cancel the process for the file: {0}?",
        "fr": "Êtes-vous sûr de vouloir annuler le processus pour le fichier: {0}?"
      },
      "processesTitle": {
        "en": "Reports generation on-going",
        "fr": "Génération de rapports en cours"
      },
      "noProcesses": {
        "en": "No reports generation on-going",
        "fr": "Aucune génération de rapports en cours"
      },
      "followProcess": {
        "en": "Follow",
        "fr": "Suivre"
      },
      "cancelProcess" : {
        "en": "Cancel",
        "fr": "Annuler"
      }
    }

    const confirmCancelProcessTitle = computed(() => {
      return app.getLocalizedText(labels.confirmCancelProcess,{
        params : (processToCancel.value && processToCancel.value.data && processToCancel.value.data.fileName) ? [processToCancel.value.data.fileName] : undefined
      })
    })
    

    const updateTableLabels = () => {
      processesFields[0].label = app.getLocalizedText(labels.headerFileName) as string;
      processesFields[1].label = app.getLocalizedText(labels.headerUser) as string;
      processesFields[2].label = app.getLocalizedText(labels.headerOptions) as string;
    }

   

    onMounted(() => {
      updateTableLabels();
    });

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
      updateTableLabels();
      },
      { deep: false }
    )

    const listLoading:Ref<boolean> = computed(() => {
      return isProcessLoading.value;
    })
    
    const emptyProcess = {};
    const processToCancel:Ref<any> = ref({...emptyProcess});

    const confirmCancelProcess = (process:any) => {
      processToCancel.value = process;
      // @ts-ignore
      cancelProcessModal.value.show()
    }

    const allowCancel = (process:any) => {
      return hasUserRole(apiRoles.superadmin) || process.user == currentUserLogin.value;
    }

    const cancelProcess = () => {

      cancelProcessAdmin(processToCancel.value.id).then((result:any) => {
        // We reset the process to cancel
        processToCancel.value = {...emptyProcess};

      });
    }

    const followProcess = (process:any) => {
      // @ts-ignore
      generateProgressModal.value.show(process.id);
    }

    return { 
      generateProgressModal,
      listLoading,
      currentProcesses,
      processesFields,
      processesElm,
      confirmCancelProcess,
      cancelProcess,
      followProcess,
      cancelProcessModal,
      processToCancel,
      labels,
      confirmCancelProcessTitle,
      currentUserLogin,
      allowCancel
    }
  }
})
</script>