<template>
  <div class="ibox generate">
    <div class="ibox-title">
      <h2>{{ $t('vat-validation.generate.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <Messages :messages="errors" type="error"></Messages>
      <Messages :messages="successes" type="success"></Messages>

      <div class="row">
        <div class="col-lg-12">
          <div class="supportedFileTypes">
            <h3>{{ getLocalizedText(labels.supportedFileTypesTitle) }}</h3>
            <div class="row">
              <div class="col-lg-auto" v-for="(fileTypeConfig, index) in supportedFileTypeConfigs" :key="fileTypeConfig.typeName + '-fileTypeConfig' + index">
                <div class="panel panel-info">
                  <div class="panel-heading">
                      {{ fileTypeConfig.typeName }}
                  </div>
                  <div class="panel-body">
                    <div>
                      {{ getLocalizedText(labels.fileTypeConfig.sheet) }}: 
                      {{ fileTypeConfig.sheetIndex != undefined ? fileTypeConfig.sheetIndex : fileTypeConfig.sheetName != undefined ? fileTypeConfig.sheetName : 1 }}
                    </div> 
                    <div>
                      {{ getLocalizedText(labels.fileTypeConfig.vatNumberColumn) }}:
                      {{ fileTypeConfig.vatNumberColumnIndex != undefined ? fileTypeConfig.vatNumberColumnIndex : fileTypeConfig.vatNumberColumnName }}
                    </div>
                    <div v-if="fileTypeConfig.vatNumberCountryColumnIndex != undefined || fileTypeConfig.vatNumberCountryColumnName != undefined">
                      {{ getLocalizedText(labels.fileTypeConfig.vatNumberCountryColumn) }}:
                      {{ fileTypeConfig.vatNumberCountryColumnIndex != undefined ? fileTypeConfig.vatNumberCountryColumnIndex : fileTypeConfig.vatNumberCountryColumnName }}
                    </div>
                    <div>
                      {{ getLocalizedText(labels.fileTypeConfig.rowStartIndex) }}:
                      {{ fileTypeConfig.rowStartIndex }}
                    </div>
                  </div>
                </div>
              </div>
              <!--
              sheetName?:string,
              sheetIndex?:number, // First sheet is 1
              headerRowIndex?:number, // First row is 1
              vatNumberColumnName?:string, // To be used with header row index is provided
              vatNumberCountryColumnName?:string, // To be used with header row index is provided
              vatNumberColumnIndex?:number, // To be used with header row index is not provided, First column is 1
              vatNumberCountryColumnIndex?:number, // To be used with header row index is not provided, First column is 1
              rowStartIndex?:number, // First row is 1
              customerNameColumnName?: string, // To be used with header row index is provided
              invoiceNumberColumnName?: string // To be used with header row index is provided
              customerNameColumnIndex?: number, // To be used with header row index is not provided, First column is 1
              invoiceNumberColumnIndex?: number // To be used with header row index is not provided, First column is 1
              -->
            </div>
          </div>
          <form role="form" @submit="onFormSubmit" name="generateForm" v-if="currentProcesses.length == 0">
            <h3>{{ getLocalizedText(labels.generateFormTitle) }}</h3>
            <div class="form-group row">
                <label for="generateSourceFile" class="col-lg-3 col-form-label">{{ getLocalizedText(labels.generateSourceFile_label) }}</label>
                <div class="col-lg-9">
                  <b-form-file
                    id="generateSourceFile"
                    v-model="generateForm.file"
                    :state="Boolean(generateForm.file)"
                    :placeholder="getLocalizedText(labels.generateSourceFile_placeholder)"
                    :drop-placeholder="getLocalizedText(labels.generateSourceFile_drop_placeholder)"
                    required
                  />
                  <div v-if="uploadPercent != undefined" class="upload progress">
                    <div :style="'width: '+uploadPercent+'%;'" class="progress-bar"></div>
                    <div v-if="uploadPercent < 100" class="progress-text">{{ getLocalizedText(labels.uploadProgress) }}</div>
                    <div v-if="uploadPercent == 100" class="progress-text">{{ getLocalizedText(labels.uploadProgressDone) }}</div>
                  </div>
                </div>
            </div>
            <SwitchCheck 
              v-if="hasUserRole(apiRoles.superadmin)"
              v-bind:value.sync="generateForm.isDebug" 
              id="isDebug" 
              :label="'Debug'" 
              :labelAsColumn="true"
            />
          </form>
          <div v-else>
            {{ getLocalizedText(labels.currentProcessesOnGoing) }}
          </div>

        </div>
      </div>

      <div class="row check" v-if="check">
        <div class="col-lg-12">
          <div :class="'panel ' + (check.validated ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ check.validated ? getLocalizedText(labels.checkValid) : getLocalizedText(labels.checkInvalid) }}
            </div>
            <div class="panel-body">
              <div class="row" v-if="check.validated">
                <div class="col-lg-3">{{ getLocalizedText(labels.fileTypeName) }}</div>
                <div class="col-lg-9">{{ check.fileTypeName }}</div>
              </div>
              <div class="row" v-if="check.validated">
                <div class="col-lg-3">{{ getLocalizedText(labels.nbRows) }}</div>
                <div class="col-lg-9">{{ check.nbRows }}</div>
              </div>
              <div class="row" v-if="check.validated">
                <div class="col-lg-3">{{ getLocalizedText(labels.nbVATNumbers) }}</div>
                <div class="col-lg-9">{{ check.nbVATNumbers }}</div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.nbEmptyRows) }}</div>
                  <div class="col-lg-9">{{ check.nbEmptyRows }}</div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.nbBadFormatVATNumbers) }}</div>
                  <div class="col-lg-9">{{ check.nbBadFormatVATNumbers }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <button class="btn btn-primary ladda-button" data-style="zoom-in" type="button" @click="onFormSubmit">{{ getLocalizedText(labels.generateButton) }}</button>
        </div>
      </div>

      <div class="row" v-if="showResetFormButton">
        <div class="col">
          <a class="btn btn-primary mt-2" href="javascript:void(0)" @click="resetForm()">{{ getLocalizedText(labels.resetButton) }}</a>
        </div>
      </div>

      <GenerateProgressModal ref="generateProgressModal" />

    </div>
  </div>
</template>

<style scoped>
  .generate-success .fa {
    font-size: 1.3em;
  }

  .supportedFileTypes .panel-body .col {
    white-space: nowrap;
  }

  .upload.progress {
    position: absolute;
    left: 15px;
    top: 0px;
    right: 15px;
    bottom: 0px;
    height: auto;
    z-index: 1;
  }

  .upload.progress .progress-text {
    position: absolute;
    color: #FFF;
    width: 100%;
    text-align: center;
    top: 10px;
  }

  .ibox-content.sk-loading:after {
    z-index:1;
  }
</style>



<script lang="ts">
import Messages from '@root/src/client/components/panels/Messages.vue';
import { Message } from '@fwk-client/store/types';
import * as Ladda from 'ladda';
import { AxiosProgressEvent } from '@fwk-node-modules/axios';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import GenerateResult from './GenerateResult.vue';
import GenerateProgressModal from './GenerateProgressModal.vue';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';

import { useGenerateAdmin } from '../../composables/useGenerateAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Messages,
      Select,
      SwitchCheck,
      GenerateResult,
      GenerateProgressModal
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { generateForm, checkFile, generateReportSocket, updateCurrentProcesses, getSupportedFileTypeConfigs, currentProcesses } = useGenerateAdmin(props, context);

      const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

      const generateProgressModal:Ref<HTMLElement|null> = ref(null);

      const check:Ref<any> = ref(null);

      const listLoading:Ref<boolean> = ref(false);

      const errors:Ref<Message[]> = ref([]);
      const successes:Ref<Message[]> = ref([]);
      const uploadPercent:Ref<number|undefined> = ref(undefined);

      const labels = {
        "supportedFileTypesTitle" : {
          "fr" : "Types de fichiers supportés",
          "en" : "Supported file types"
        },
        "fileTypeConfig" : {
          "sheet" : {
            "fr" : "Feuille",
            "en" : "Sheet"
          },
          "vatNumberColumn" : {
            "fr" : "Colonne numéro TVA",
            "en" : "VAT number column"
          },
          "vatNumberCountryColumn" : {
            "fr" : "Colonne pays numéro TVA",
            "en" : "VAT number country column"
          },
          "rowStartIndex" : {
            "fr" : "Index de la première ligne",
            "en" : "First row index"
          }
        },
        "currentProcessesOnGoing" : {
          "fr" : "Une génération est en cours. Veuillez patienter avant de pouvoir générer un nouveau rapport.",
          "en" : "A generation is ongoing. Please wait before generating a new report."
        },
        "generateFormTitle" : {
          "fr" : "Générer un rapport",
          "en" : "Generate a report"
        },
        "generateSourceFile_label" : {
          "fr" : "Fichier",
          "en" : "File"
        },
        "generateSourceFile_placeholder" : {
          "fr" : "Sélectionnez le fichier",
          "en" : "Select the file"
        },
        "generateSourceFile_drop_placeholder" : {
          "fr" : "Sélectionnez le fichier ou déposez le ici",
          "en" : "Select the file or drop it here"
        },
        "uploadProgress": {
          "fr" : "Téléchargement en cours...",
          "en" : "Uploading..."
        },
        "uploadProgressDone": {
          "fr" : "Téléchargement terminé",
          "en" : "Upload done"
        },
        "checkValid" : {
          "fr" : "Fichier valide",
          "en" : "Valid file"
        },
        "checkInvalid" : {
          "fr" : "Fichier invalide",
          "en" : "Invalid file"
        },
        "fileTypeName" : {
          "fr" : "Type de fichier",
          "en" : "File type"
        },
        "nbRows" : {
          "fr" : "Nombre de lignes",
          "en" : "Number of rows"
        },
        "nbVATNumbers" : {
          "fr" : "Nombre de numéros TVA",
          "en" : "Number of VAT numbers"
        },
        "nbEmptyRows" : {
          "fr" : "Nombre de lignes vides",
          "en" : "Number of empty rows"
        },
        "nbBadFormatVATNumbers" : {
          "fr" : "Nombre de numéros TVA mal formattés",
          "en" : "Number of bad formatted VAT numbers"
        },
        "generateButton" : {
          "fr" : "Générer",
          "en" : "Generate"
        },
        "resetButton" : {
          "fr" : "Réinitialiser",
          "en" : "Reset"
        }
      }

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {

      })

      const enableLaddaButton = () => {
        var button:HTMLButtonElement|null = document.querySelector( '.check button' );
        laddaSubmit = Ladda.create(button!);
      }

      const onUploadProgress = (progressEvent:AxiosProgressEvent) => {
        if(progressEvent.lengthComputable) {
          var percent = Math.round((progressEvent.loaded / progressEvent.total!) * 100);
          uploadPercent.value = percent;
        }
      }

      const onApiErrors = (apiErrors:Message[]) => {
        errors.value = apiErrors;
      }

      const onApiSuccesses = (apiSuccesses:Message[]) => {
        successes.value = apiSuccesses
      }

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        // We clear the current messages
        errors.value = [];
        successes.value = [];

        laddaSubmit!.start();

        generateReportSocket().then((result:any) => {
          updateCurrentProcesses();
          if(result && result.processID) {
            // @ts-ignore
            generateProgressModal.value.show(result.processID);
          }
          laddaSubmit!.stop();
          resetForm();
        })
      }

      const showResetFormButton = computed(() => {
        return generateForm.file != null || check.value != null;
      })

      const resetForm = () => {
        generateForm.file = null;
        check.value = null;
        uploadPercent.value = undefined;
      }

      const supportedFileTypeConfigs:Ref<any[]> = ref([]);
      getSupportedFileTypeConfigs().then((result) => {
        supportedFileTypeConfigs.value = result.fileTypeConfigs;
      })

      watch(
        () => generateForm.file,
        (val:any, oldVal:any) => {
          if(val != undefined && val.name != "") {
            check.value = null;
            listLoading.value = true;
            uploadPercent.value = undefined;

            // We clear the current messages
            errors.value = [];
            successes.value = [];
            
            checkFile({errorsHandler: onApiErrors, progressHandler:onUploadProgress}).then((result) => {
              listLoading.value = false;
              if(result.validated) {
                uploadPercent.value = undefined;
                check.value = result,
                nextTick(() => {
                  enableLaddaButton();
                })
              }
              else {
                resetForm();
              }
            })
          }
          else {
            // We reset the check file output
          }
        },
        { deep: true }
      )

      return { 
        errors,
        successes,
        listLoading,
        onFormSubmit,
        resetForm,
        generateForm,
        check,
        labels,
        hasUserRole,
        apiRoles,
        generateProgressModal,
        showResetFormButton,
        supportedFileTypeConfigs,
        currentProcesses,
        uploadPercent
      }
    }
})
</script>